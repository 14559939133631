<template>
  <div class="user-activation has-background-white">
    <div class="columns is-centered">
      <div class="column is-8">
        <img :src="$asset('/images/branding/hypefactors_logo.svg')" alt="">
      </div>
    </div>
    <transition name="fade">
      <section v-if="activating && !expired" class="user-activation__activating section">
        <div class="section-body">
          <div class="has-text-centered is-size-5">
            <i class="hf hf-spinner hf-pulse" /> {{ $t('components.activate_user.activating_user') }}...
          </div>
        </div>
      </section>
      <section v-if="expired" class="user-activation__expired section">
        <div class="section-body">
          <transition name="fade">
            <div v-if="!activationSent" class="form">
              <h5 class="title is-5">
                {{ $t('components.activate_user.activation_code_expired') }}
              </h5>
              <div class="subtitle is-6">
                {{ $t('components.activate_user.input_email') }}
              </div>
              <form @submit.prevent="sendNewActivation">
                <form-field
                  :validator="$v.form.email"
                  attribute="Email"
                >
                  <template slot="control">
                    <div class="field is-grouped">
                      <div class="control is-expanded">
                        <input
                          v-model="form.email"
                          class="input"
                          type="email"
                          name="email"
                          placeholder="Email"
                          @input="$v.form.email.$touch()"
                        >
                      </div>
                      <div class="control">
                        <button
                          :class="{ 'is-loading': isLoading }"
                          class="button is-primary"
                        >
                          {{ $t('forms.send') }}
                        </button>
                      </div>
                    </div>
                  </template>
                </form-field>
              </form>
            </div>
            <div v-else class="notification is-success">
              {{ $t('success.successfully_activated_user') }}
            </div>
          </transition>
        </div>
      </section>
    </transition>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  props: {
    activationToken: { type: String, required: true },
    userId: { type: String, required: true }
  },

  validations: {
    form: {
      email: { required, email }
    }
  },

  data () {
    return {
      activating: true,
      expired: false,
      activationSent: false,
      isLoading: false,
      form: {
        email: ''
      }
    }
  },

  computed: {
    redirectTo () {
      return this.$route.query.redirect
    }
  },

  mounted () {
    this.tryToActivateUser()
  },

  methods: {
    async tryToActivateUser () {
      try {
        const { data } = await this.$api.post('activation/complete', {
          token: this.activationToken
        })

        this.activating = false
        this.$notify.success(this.$t('success.successfully_activated_user'))

        await this.$store.dispatch('logoutGlobally')

        await this.$store.dispatch('syncUpGlobalAuth', {
          accessToken: data.data.access_token,
          refreshToken: '' // we dont have it at this endpoint
        })
        try {
          const axiosResponse = await this.$api.get('user/organisations')
          const userBrands = axiosResponse.data.data

          if (userBrands.length === 0) {
            this.$router.replace({
              name: 'onboarding',
              params: { userId: this.userId }
            })
          } else {
            this.redirectToLogin()
          }
        } catch (error) {
          this.redirectToLogin()
        }
      } catch (error) {
        this.activating = false
        const statusCode = this.$safeGet(error, 'response.status')
        if (statusCode === 404) {
          /* Activation code is bad */
          this.$displayRequestError(error, this.$t('errors.cannot_activate_user'))
          this.followRedirectQueryParameter()
        } else if (statusCode === 304) {
          /* Activation code is already used */
          this.followRedirectQueryParameter()
          this.$notify.success(this.$t('success.user_already_activated'))
        } else if (statusCode === 410) {
          /* Activation code is expired */
          this.expired = true
        }
      }
    },

    followRedirectQueryParameter () {
      if (!this.redirectTo) {
        return this.$router.replace({ name: 'logout', query: { no_message: true } })
      }
      this.$locationService.replace(this.redirectTo)
    },

    redirectToLogin () {
      if (this.redirectTo) {
        return this.followRedirectQueryParameter()
      }
      this.$router.replace({
        name: 'login',
        query: this.$route.query
      })
    },

    sendNewActivation () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.isLoading = true
      this.$api.post('activation/resend', this.form)
        .then(response => {
          this.isLoading = false
          this.activationSent = true
          this.$notify.success(this.$t('success.sent_new_email_with_token'))
        })
        .catch(error => {
          const status = this.$safeGet(error, 'response.status')
          // User is already activated
          if (status === 410) {
            this.$router.replace({ name: 'login' })
          }
          this.isLoading = false
          this.$displayRequestError(error)
        })
    }
  }
}
</script>
